<template>
  <div class="mb-2">
    <div class="">
      <!-- <div class="text-xs uppercase">Ventas por día</div> -->
      <div class="flex items-center justify-between px-1 mb-4">
        <div>
          <div class="text-gray-900">Grafico de Ventas</div>
          <div class="text-xs italic text-gray-700">
            Ventas por día, basado en el filtro de fechas
          </div>
        </div>

        <div class="f">
          <w-select label="Moneda" class="z-40" v-model="key" :options="keys" />
        </div>
      </div>
      <div id="wrapper" class="relative">
        <div
          v-if="loading"
          class="
            absolute
            inset-0
            z-20
            flex
            items-center
            justify-center
            bg-gray-300
            opacity-75
          "
        >
          <w-loading class="z-30" />
        </div>
        <bar-chart
          :height="200"
          :data="data"
          :labels="labels"
          :options="options"
          :currency="key === 'total' ? currency : false"
          :tick-format="tickFormat"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import LineChart from "@/components/charts/Line";
import BarChart from "@/components/charts/Bar";
import moment from "moment";
export default {
  components: {
    BarChart,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    chart: {
      type: Object,
      default: () => {},
    },
    currency: {
      type: String,
      default: () => {
        return "ars";
      },
    },
  },

  data() {
    return {
      options: {},
      rawData: [],
      key: "total",
      keys: [
        { label: "Ventas", key: "total" },
        { label: "Cantidad", key: "count" },
      ],
    };
  },

  computed: {
    // ...mapGetters({ dates: "dashboard/dates", filter: "dashboard/filter" }),
    tickFormat() {
      return "DD MMM";
      // var days = this.dates.to.diff(this.dates.from, "days");
      // if (days > 1) return "DD MMM";
      // return "HH[hs]";
    },
    groupBy() {
      return "day";
      // var days = this.dates.to.diff(this.dates.from, "days");
      // if (days > 1) return "day";
      // return "hour-of-day";
    },
    labels() {
      return Object.keys(this.chart).map((date) => {
        return moment(date, "DD-MM-YYYY").format("DD MMM").toString();
      });

      //   var ret = [];
      //   var days = this.dates.to.diff(this.dates.from, "days");
      //   var from = this.dates.from.clone();
      //   if (days > 1) {
      //     for (let i = 0; i <= days; i++) {
      //       ret.push(from.clone());
      //       from.add(1, "days");
      //     }
      //   } else {
      //     //Agrupar por horas
      //     for (let i = 0; i <= 23; i++) {
      //       ret.push(from.clone());
      //       from.add(1, "hours");
      //     }
      //   }
      //   return ret;
    },
    data() {
      let data = Object.values(this.chart).map(
        (el) => el[this.currency][this.key]
      );
      return data;
      // var data = [...Array(this.labels.length)].fill(0);
      // this.rawData.forEach((item) => {
      //   console.log(item);
      //   if (this.groupBy == "day") {
      //     var date = moment(item._id, "DD-MM-YYYY");
      //     const index = this.labels.findIndex((moment) =>
      //       moment.isSame(date, "day")
      //     );
      //     if (index > -1) data[index] = item.total;
      //   }
      //   if (this.groupBy == "hour-of-day") {
      //     const index = this.labels.findIndex(
      //       (moment) => moment.format("HH") == item._id
      //     );
      //     if (index > -1) data[index] = item.total;
      //   }
      // });
      // return data;
    },
    totalSales() {
      // if (this.data && this.data.length > 0)
      //   return this.data.reduce((total, payment) => total + payment);
      // else return 0;
      return 0;
    },
  },
  watch: {
    filter() {
      this.load();
    },
    dates() {
      this.load();
    },
  },
  mounted() {
    // console.log("this.dates.to", this.dates.to);
    this.load();
  },
  methods: {
    async load() {
      console.log("load()");
      // this.loading = true;
      // var query = {
      //   where: {
      //     createdAt: {
      //       between: [
      //         this.dates.from.startOf("day"),
      //         this.dates.to.endOf("day"),
      //       ],
      //     },
      //     status: "payed",
      //   },
      // };
      // if (this.filter) {
      //   if (this.filter.type === "page") query.where.pageId = this.filter.id;
      //   if (this.filter.type === "store") query.where.storeId = this.filter.id;
      // }

      // this.rawData = await api.groupby(
      //   "orders",
      //   {
      //     tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      //     groupby: this.groupBy,
      //     sum: "total",
      //   },
      //   query
      // );
      // console.log("Raw Data", this.rawData);
      // this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
